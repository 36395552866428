<template>
  <div class="protocol">
    <div class="head">也在网会员服务条款</div>
    <br />
    <div>版本更新日期:2021年10月26日</div>
    <div>版本生效日期:2021年11月01日</div>
    <br />
    <div class="">
      欢迎您使用也在网的服务！

      <div class="">
        也在网是一个严肃纯净的婚恋交友平台，由佛山市蝶与花信息技术有限公司及其关联公司（以下统称“也在网”）负责运营。也在网会员服务条款（以下简称“本协议”）是会员（以下亦称
        “您”，指注册、登录、使用也在网服务的个人）与也在网之间的协议，也在网将严格依照本协议条款提供产品和服务。您注册、登录或使用也在网的产品和服务（统称为“服务”），便表示您同意并接受本协议的全部条款。如果您不同意本协议的任一条款，您应不使用或停止使用也在网提供的任何服务。
      </div>
      <div class="title">一、服务协议的确认和接受</div>
      <div class="">
        <span
          class="title">1、您确认：您在使用也在网服务之前已认真阅读、充分理解下述各条款，同时已重点阅读协议中以加粗字体显示的内容，特别是本协议含有免除或者限制也在网责任的条款、限制会员权利的条款、约定争议解决方式和管辖的条款等。</span>除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的确认，并同意接受本协议全部内容。也在网<span
          class="title">平台上出现的相关操作提示、答疑指南、使用帮助或也在网以其他方式向您展示的规则、流程等均构成您使用也在网服务的相关规则的一部分，与本协议具有同等效力。</span>
      </div>
      <div class="">
        <span
          class="title">2、您同意：也在网有权根据需要随时对本协议及相应的服务规则进行单方面变更，并有权以消息推送、网页公告等方式予以公布，公布后即自动生效。若您在本服务协议内容公告变更后继续使用也在网服务的，表示为您已充分阅读、理解并接受更新后的协议，也将遵守更新后的协议。</span>若您不同意相关变更，应当立即停止访问也在网或使用也在网服务并申请注销账号。
      </div>
      <div class="">
        <span
          class="title">3、您通过也在网指定方式开通付费会员服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，且不会因此向也在网提出任何主张或追究也在网的任何责任。</span>上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
      </div>
      <div class="title">二、服务内容与说明</div>
      <div class="">
        1、也在网的服务分为线上服务和线下服务。“线上服务”是指也在网通过互联网为您提供的婚恋交友服务；具体服务以也在网实际提供为准。“线下服务”是指也在网和也在网授权的第三方（具体名单详见也在网公示的内容，也在网对该名单将不时予以更新）作为“也在网线下服务中心”为您提供的服务。也在网有权根据实际情况修改、替换、升级也在网提供的任何服务，并在相关页面予以告知。如果会员不同意或者不接受也在网的上述变更，请直接停止使用也在网的服务，否则视为会员同意并接受也在网提供的任何服务，同时该同意并接受的行为仍受本协议约束。
      </div>
      <div class="">
        2，也在网作为网络服务平台，为会员提供婚恋、交友信息服务。为使用也在网的服务，您应自行配备进入互联网所必需的设备（如电脑、手机等装置），并自行支付登陆互联网所需要的费用。也在网提供的网络服务既包括会员通过其账号自行进行即时通讯、互动娱乐、发布动态、关注他人、发布视频等服务也包括会员主动授权也在网为其提供问候或恋爱邀约异性的服务。
      </div>
      <div class="">
        3、也在网<span class="title">提供的服务包括免费服务与收费服务。会员可以通过付费方式购买收费服务，每一笔费用均包含30%的服务费。</span>
        关于收费服务的具体服务内容、资费标准以也在网官方相关页面公布为准。会员只有完成了支付行为，才能使用相应收费服务。如您使用收费服务，需遵守相关的专项规则。<span
          class="title">除法律明确规定或也在网另有说明外，所有收费服务均不支持退费。</span>
      </div>
      <div class="title">
        4、为了促进会员互动，也在网提供的部分服务应用（包括但不限于右滑喜欢、一键打招呼等）中带有触发问候、招呼的功能。会员在使用此类应用时会给符合自己征友要求的异性发去问候招呼。也在网不能保证会员通过此类应用发出或接收到的信息完全满足会员的交友需求。会员理解并同意接收也在网因此类服务发送的信息。
      </div>
      <div class="title">
        5、为了节省择偶时间、帮助会员与心仪对象建立联系，也在网推出推荐、牵线等服务。此类服务是在会员同意并主动授权的情况下帮助授权会员寻找符合其征友要求的异性，并经授权会员同意向符合其征友要求的异性发出会话请求或恋爱邀请，进行人工牵线。会员理解并同意接收也在网因此类服务发送的站内信、邮件、短信或电话、微信通知。
      </div>
      <div class="">
        <span
          class="title">6、为提供全面、优质的婚恋交友服务、婚介服务，也在网会通过发送站内信、电子邮件、短信或电话、微信通知等方式向您发送信息（内容包括但不限于服务流程确认，其他用户希望联系、约见，活动信息和咨询，新服务或新产品的推介、提示再次登录本网站、业务推广、安全提示等）；</span>您注册也在网或实际使用也在网的服务，即是明确同意接收此类信息；您如不愿意接收此类信息，您有权拨打客服电话或通过也在网提供的退订功能进行退订。如您未退订，则视为同意接收此类信息。
      </div>
      <div class="">
        7、对于利用也在网服务进行非法活动，或其言行（无论线上或者线下的）背离也在网严肃交友目的的，也在网将严肃处理，采取包括但不限于警告、加入黑名单、关闭会员帐号等处罚措施。
      </div>
      8、为提高也在网会员交友的成功率，也在网有权将也在网会员的交友信息在也在网的合作网站上进行展示或其他类似行为。
      <div class="">三、会员注册规则</div>
      <div class="">
        1、您确认：在您注册成为也在网的会员或使用也在网时，已满足下列全部条件：
      </div>
      <div class="title">
        1）在注册之日必须为年满18周岁的完全民事行为能力人；<br />
        2）在注册之日以及此后使用也在网交友服务期间必须是单身状态，包括未婚、离异或是丧偶；<br />
        3）在注册之日以及此后使用也在网交友服务期间必须以恋爱或者婚姻为目的。<br />
      </div>
      <div class="">
        在使用也在网交友服务期间，如果您的状态发生变更，已经不符合会员注册资格，请联系也在网注销您的会员资格，否则由此产生的责任由您自行承担。
      </div>
      <div class="title">
        2、您在使用也在网服务之前需注册一个也在网账号，成为也在网的会员，会员账号应当使用真实有效的手机号码绑定注册，并经短信验证。请您使用尚未与也在网绑定的手机号码，以及未被也在网根据本协议封禁的手机号码注册也在网会员账号。
      </div>
      <div class="">3、为更好地享有也在网提供的服务，您同意并保证：</div>
      <div class="title">
        1）您向也在网提供的本人资料是真实、合法、准确、完整及最新的，不得通过任何手段恶意注册也在网账号（包括但不限于使用无效手机号、无效未验证邮箱、使用他人身份等恶意注册）；
        2）上述信息发生变更，您应及时进行更新；
        3）征友过程中，务必保持也在网账号的唯一性。
        若会员提供任何错误、不实或不完整的资料，或也在网有理由怀疑资料为错误、不实或不完整及违反会员注册条款的，或也在网有理由怀疑其会员资料有悖于“严肃纯净的婚恋交友”主题的，也在网有权修改或删除会员资料，暂时或永久关闭该会员的帐号，或暂停或终止提供也在网的全部或部分服务。
      </div>
      <div class="">
        <span
          class="title">4、会员必须确保自己是也在网账号的合法使用权人，并应妥善保管您的账号、密码的安全，并对其该账号密码下所从事的所有行为承担责任。会员账号具有唯一性和不可转让性，您不得将会员账号以任何方式提供给他人使用，包括但不限于转让、出租、出借、售卖或分享等方式。</span>否则，因此产生一切纠纷、损失和法律责均由会员自行承担，且也在网有权根据实际情况暂时关闭或永久关闭此账号。因会员保管不善导致盗号或密码失窃，进而导致资料、信息泄漏的，由会员自行承担不利后果。当会员的账号或密码遭到未经授权的使用时或者发生其它任何安全问题时，会员应当立即通知也在网，否则未经授权的使用行为均视为会员本人的行为。
      </div>
      <div class="title">
        四、会员服务使用规则<br />
        1、会员在使用也在网服务时，应遵守宪法法律，包括但不限于《中华人民共和国民法典》、《中华人民共和国著作权法》、《全国人民代表大会常务委员会关于维护互联网安全的决定》、《中华人民共和国保守国家秘密法》、《中华人民共和国电信条例》、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《互联网电子公告服务管理规定》、《中华人民共和国网络安全法》等与互联网信息服务有关的法律法规；同时应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等行为。
        <br />
        在任何情况下，也在网一旦有理由认为会员使用也在网服务中的任何行为违反或可能违反上述法律和法规的任何规定，也在网可在任何时候不经事先通知终止向该会员提供服务。
      </div>
      <div class="">
        2、会员知悉并同意：在使用也在网服务时，禁止从事下列行为:<br />
        1)制作、上传、复制、发布、传播或以其它方式传送含有下列信息的内容（包括但不限于照片、文字、图片、语音、视频、图文、信息或其他资料）：<br />
        ①反对宪法所确定的基本原则的；<br />
        ②危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
        ③损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的；<br />
        ④破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
        ⑤散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
        ⑥散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br />
        ⑦侮辱或者诽谤他人，侵害他人合法权利的；<br />
        ⑧
        含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它有悖道德、令人反感的内容；<br />
        ⑨含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的。<br />
        2)以任何方式危害未成年人。
        3)冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或机构有关。<br />
        4)将侵犯任何人的肖像权、名誉权、隐私权、专利权、商标权、著作权、商业秘密或其它专属权利的内容上载、张贴、发送电子邮件或以其它方式传送的。<br />
        5）将广告函件、促销资料、"垃圾邮件"等，加以上载、张贴、发送电子邮件或以其他方式传送，供前述目的使用的专用区域除外；<br />
        6)伪造标题或以其他方式操控识别资料，使人误认为该内容为也在网所传送；<br />
        7)跟踪或以其它方式骚扰其他会员，非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的行为；<br />
        8）将病毒或其它计算机代码、档案和程序，加以上载、张贴、发送电子邮件或以其它方式传送的。<br />
        9)未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。<br />
        10）利用也在网提供的服务设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组，或利用也在网发布涉及实施诈骗（如花篮托、酒吧托、机票托、六合彩等），制作或者销售违禁物品、管制物品以及其他违法犯罪活动的信息；<br />
        11）干扰或破坏也在网服务或与也在网服务相连的服务器和网络，或违反本协议干扰也在网服务。<br />
        12）对也在网提供的服务或服务的任何部分，进行复制、拷贝、出售、转售或用于任何其他商业目的。<br />
        13）故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范或违背严肃婚恋交友目的行为。<br />
      </div>
      <div class="title">
        3、会员在也在网平台制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的，应保证其上传内容不违反法律法规及其规范性文件，亦不侵犯任何第三方的合法权益，否则导致的一切不利后果均由会员自行承担；造成也在网损失的，还应向也在网承担赔偿责任包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。同时：
      </div>
      <div class="">
        1）为了维护法律法规及相关规范性文件、保护他人的合法权益、维护也在网的信誉及安全，也在网有权直接修改、删除、下线、屏蔽会员利用也在网服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容，而无需事先通知会员；<br />
        2）也在网有权视情节严重程度对该会员做出警告、加黑、关闭会员账号的处罚。<br />
        3）也在网的后台记录有可能作为会员违反法律法规、违约、侵权的证据。<br />
        4）除特别签订协议或者也在网许可外，会员利用也在网服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容不得含有广告信息，包括且不限于上述的标题、简介等内容。<br />
      </div>
      <div class="title">
        4、会员应当保证，不会利用也在网服务或其衍生服务侵犯也在网以及他人的合法权益，禁止通过网络漏洞、恶意软件或其他手段破坏也在网的正常经营，窃取、盗用他人的账号及账号下财产等；禁止侵犯他人合法权益或违反本使用规则的其他内容。否则，也在网有权根据情节严重而对该会员及会员账号采取措施：包括但不限于发出警告、加黑、关闭账号等处罚，并无需退还该会员已付的任何费用（如有）。如因此造成也在网损失的，会员应负责全部赔偿。构成犯罪的，也在网有权移交司法部门处理。<br />
        5、会员在投诉其他会员有违法违规行为或违反本协议情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给也在网造成损失的，投诉人应承担相应的赔偿责任。
      </div>
      <div class="title">五、会员个人信息与隐私保护</div>
      <div class="">
        1、个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。您在下载、安装、启动、浏览、注册、登录、使用也在网的产品和服务时，也在网<span
          class="title">
          将按照平台公布的《也在网隐私保护政策》处理和保护您的个人信息，</span>因此希望您能够仔细阅读、充分理解<span class="title">《也在网隐私保护政策》。</span>
      </div>
      <div class="title">
        2、您应当在仔细阅读、充分理解《也在网隐私保护政策》后使用也在网的产品和服务，如果您不同意政策的内容，您应立即停止访问/使用也在网的产品和服务。您使用或继续使用也在网提供的产品和服务的行为，都表示您充分理解和同意《也在网隐私保护政策》（包括更新版本）的全部内容。
      </div>
      <div class="">
        3、也在网深知个人信息对您的重要性，因此也在网非常重视保护您的个人信息和隐私，亦将您的个人信息以高度审慎的义务对待和处理。<span
          class="title">更多关于个人信息收集、使用和保护规则、您对个人信息的管理等内容，请您至也在网平台上查阅《也在网隐私保护政策》。</span>
      </div>
      <div class="title">
        六、免责条款<br />
        1、会员理解并确认，也在网的产品和服务是按照现有技术和条件所能达到的现状提供的，也在网无法保证其所提供的产品和服务毫无瑕疵。本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、网络中断、会员关机、通信线路原因等造成的服务中断或不能满足会员要求和期望的风险。出现上述情况时，也在网将努力在第一时间与相关单位配合，及时进行修复，但由此给会员或第三方造成的损失，也在网不承担法律责任。<br />
        2、会员理解并确认，会员通过也在网服务所上传、分享的任何内容并不代表和反映也在网的观点。对于会员通过也在网上传、分享的内容，也在网会尽合理努力按照国家有关规定严格审查，但无法完全控制经由也在网服务传送的内容，不保证内容的正确性、完整性或品质。在使用也在网服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的会员或第三方的任何损失，也在网不承担法律责任。但也在网有权依法停止传输前述内容并采取相应行动。<br />
        3、对于通过也在网相关页面展示的各种广告信息、链接、资讯等，也在网会依照法律规定对广告商履行相关义务，会员知悉并同意，这些广告商和广告内容非由也在网控制，会员对广告信息应审慎判断其真实性、合法性、可靠性。会员通过也在网的产品和服务与广告商或广告主进行任何形式的行为或产生任何形式的纠纷，由会员自行负责与解决，除法律规定由广告发布者承担的责任外，也在网不承担额外责任，但根据需要会依法提供必要的协助。<br />
        4、对于会员上传的照片、资料、证件及填写的个人信息等，也在网已采用相关措施并已尽合理努力进行审核，但不保证其内容的真实性、合法性或可靠性，相关责任由上传上述内容的会员承担。<br />
        5、您理解并确认：会员以自己的独立判断从事与交友相关的行为，并独立承担可能产生的不利后果和责任，对非因也在网原因而直接引起的会员损失，也在网不承担法律责任。<br />
        6、会员使用经由也在网下载或取得任何资料，由会员自行考虑并自负风险；因任何资料的下载而导致的会员电脑系统的任何损坏或数据丢失等后果，也在网不承担法律责任。<br />
        7、也在网对所有会员自发组织的活动、自发成立的组织不负法律责任。<br />
        8、对于也在网策划、发起、组织或是承办的任何会员活动（包括但不限于收费活动、公益活动），也在网不对上述活动的效果向会员作出任何保证或承诺，也不担保活动期间会员自身行为的合法性、合理性。由此产生的任何对于会员个人或者他人的人身或者是名誉以及其他损害，也在网不承担法律责任。<br />
        9、对于会员的投诉，也在网将尽合理努力认真核实，但不保证最终公之于众的投诉的真实性、合法性，对于投诉内容侵犯会员隐私权、名誉权等合法权利的，所有法律责任由投诉者承担，与也在网无关。<br />
        10、尽管也在网已采取相应的技术保障措施，但会员仍有可能收到各类的广告信（也在网发送的广告信除外）或其他不以交友为目的邮件，也在网不承担法律责任。<br />
        11、除也在网官方公布的方式外，会员通过其他未经过也在网合法授权的渠道、途径、方式获取的也在网的产品与服务（包括且不限于账号、线下vip服务、也在网礼物）均为非法取得，也在网概不承认其效力，且一经发现也在网有权立即做出删除、取消、清零、封号等处理，因此导致的一切不利后果均由会员自行承担。<br />
        12、会员因缺少身份认证或认证信息不真实而导致账号、账号内财产等丢失、减少而无法找回的，也在网不承担法律责任。<br />
        13、也在网有权独立判断会员的行为是否违反中国法律法规或本协议约定或违背严肃婚恋交友目的；如存在上述任一行为，也在网有权视情节轻重对违规帐号处以包括但不限于警告、加黑、关闭账户等处罚；并有权依法保存有关信息并向主管部门报告。该权利不构成也在网的义务或承诺，也在网不能保证及时发现违法行为或进行相应处理。<br />
        14、除法律明确规定外，也在网对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有法律责任。
      </div>
      <div class="title1">
        15、您理解并同意，也在网不对因下述情况而导致您的损害赔偿承担责任：<br />
        (1)因您原因未能使用也在网服务。<br />
        (2)第三方未经批准的使用您的账号或更改您的数据。<br />
        (3)您对也在网服务的误解。<br />
        (4)非因也在网的原因而引起的与也在网服务有关的其它损失。<br />
        (5)因您进行上传或张贴，而导致第三方提出侵权或索赔要求的，您承担全部责任；也在网仅承担权利方告知后的配合删除屏蔽等法定义务。<br />
        (6)第三方对于您在也在网的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与也在网无关。<br />
      </div>
      <div class="title">七、知识产权</div>
      <div class="">
        1、也在网尊重知识产权并注重保护会员享有的各项权利。会员在也在网服务中上载或发布内容（包括但不限于个人照片、肖像、文字、交友成功故事等）或授权也在网的行为不会对会员就上述内容所享有的知识产权产生不良影响，上载或发布的作品的著作权仍归作品的创作者所有。
        <span
          class="title">为了更好地对会员、会员上载或发布的内容以及也在网交友宣传推广，会员同意在全世界范围内，免费、非独家授予也在网对该信息行使发布、使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；对会员的上传、发布信息进行商业开发的权利；通过有线或无线网络向会员的计算机终端、移动通讯终端等提供信息的下载、点播、数据传输及相关的宣传和推广等服务的权利。该授权持续至会员书面通知也在网不得继续使用，且也在网实际收到该等书面通知时止。</span>
      </div>
      <div class="">
        2、除本服务中涉及广告的知识产权由相应广告商享有外，也在网在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归也在网所有，但会员对自己发布的内容已合法取得知识产权的除外。
      </div>
      <div class="">
        3、也在网提供本服务时所依托软件的著作权、专利权及其他知识产权均归也在网所有。也在网在本服务中所涉及的图形、文字或其组成，以及其他也在网标志及产品、服务名称，其著作权或商标权归也在网所有。
      </div>
      <div class="title">
        4、上述及其他任何也在网或相关广告商依法拥有的知识产权均受到法律保护，未经也在网或相关广告商书面许可，会员不得以任何形式进行使用或创造相关衍生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由会员负责，也在网将依法追究违约方的法律责任。
      </div>
      <div class="title">八、服务终止与账号注销</div>
      <div class="title">
        1、如发生下列任何一种情形，也在网有权终止向会员提供的免费服务或收费服务并关闭相关账号，而无需对会员或任何第三方承担任何责任：
      </div>
      <div class="">
        1)会员违反相关法律法规或本协议的约定；<br />
        2)按照法律规定或有权机关的要求；<br />
        3)会员连续二年未使用也在网账号；<br />
        4)出于安全的原因或其他必要的情形。<br />
        2、会员若反对任何注册条款的内容或对之后注册条款修改有异议，或对也在网服务不满，会员有以下权利：<br />
        1)停止访问或使用也在网服务；<br />
        2)通知也在网注销您的账户。<br />
        3、账号注销<br />
        1）会员可通过也在网直接申请注销账户，或通过给管理员发信、联系客服的方式向也在网提出注销申请。经过也在网审核确认后，予以注销账号。<br />
        2）会员向也在网提出账号注销申请，经核实存在以下情形的，也在网有权拒绝会员的注销申请：<br />
        ①账号中有未消耗完毕的余额和虚拟权益（包括账户余额、通过充值或参加活动取得的卡券、礼品等虚拟财物）；<br />
        ②账号有尚未完成的交易；<br />
        ③账号有正在进行的业务（如申请找密码、修改手机号等）；<br />
        ④账号有纠纷或争议（如投诉举报、被投诉举报、被国家机关调查或者正处于诉讼、仲裁程序中）；<br />
        ⑤账户存在与其他网站、APP的授权登录或绑定关系。<br />
        ⑥账号有其他不符合注销账号的情形。<br />
        <span
          class="title">3）会员确认注销账号是不可恢复的操作，会员应自行备份与也在网账号相关的信息和数据，会员确认操作之前与也在网账号相关的所有服务均已进行妥善处理。</span>会员已充分知晓并确认，账号注销后将会员将不再拥有账号相关的权益，包括但不限于：<br />
        ①账号注销后将无法在也在网的任何产品继续登录、使用账号，且不可恢复；<br />
        ②账号信息、账户中的余额、虚拟财物、会员权益、会员存储的信息资料等将被清空，且无法恢复；<br />
        ③账号的交易记录将被清空且无法恢复；<br />
        ④账号注销后，也在网不再为会员提供任何与账号有关的服务。<br />
        ⑤因注销账号所造成的不便或不利后果，由会员自行承担，与也在网无关。<br />
      </div>
      <div class="title">九、会员信息储存</div>
      <div class="">
        1、也在网<span class="title">将尽力维护也在网交友服务的安全性及方便性，但对会员在本服务中相关数据的删除或储存失败不承担任何责任。</span>
      </div>
      <div class="">
        2、也在网可以根据实际情况自行决定会员在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。会员可根据自己的需要自行备份本服务中的相关数据。<br />
        3、如会员停止使用本服务或本服务终止，也在网可以从服务器上永久地删除会员的数据。本服务停止或终止后，也在网没有义务向会员返还任何数据。
      </div>
      <div class="title">十、法律适用、争议和管辖</div>
      <div class="">
        1、您知悉并同意：本协议的生效、履行、解释及争议的解决均适用中华人民共和国的现行法律；<span
          class="title">如会员与也在网就本协议内容或其执行发生任何争议，首先应友好协商解决；协商不成时，会员同意将纠纷或争议提交至佛山市顺德区仲裁委员会，并适用提起仲裁时该会现行有效的仲裁规则进行仲裁，其仲裁裁决是终局的。</span>
      </div>
      <div class="">
        2、会员之间争议的解决。<span
          class="title">您理解并同意也在网仅仅是为您和其他会员的婚恋交友提供信息服务的平台，但对于您与其他也在网会员的来往，无论是在网上或在现实生活中，您都须对您的行为承担全部责任。如您与其他会员产生争议，则您理解并同意也在网并没有法定义务监控或处理您与其他会员之间的争议。</span>
      </div>
      <div class="">
        3、本协议因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。<br />
        4、本协议的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。
      </div>
      <div class="title">
        风险提示：会员应增强自我保护和防范意识。在任何情况下，会员不应轻信借款、投资、理财、赌彩或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意也在网关于防范诈骗的提示和指南。
      </div>
      <br />
      <div class="write">也在网</div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
export default {
  metaInfo: {
    title: "也在网-服务协议",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        name: "keywords",
        content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
      }, {
        name: "description",
        content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
      }
    ],
  },
};
</script>

<style lang="less">
.protocol {
  font-size: 20px;
  color: #666;
  padding: 30px;
  text-align: justify;
  line-height: 30px;

  .head {
    text-align: center;
    color: #666;
    font-size: 26px;
    font-weight: 600;
  }

  .a {
    padding-left: 414px;
  }

  .write {
    text-align: right;
    font-size: 28px;
    font-weight: 600;
  }

  .title1 {
    color: #333;
  }

  .time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    color: #333;
    font-weight: 600;
  }

  .aa {
    color: #fd686e;
  }

  div {
    margin-top: 15px;
  }
}
</style>
